import React from 'react';
import styled from "styled-components";
import CustomerData from "../content/customer.json"

const Styles = styled.footer`
  background: var(--dark-gray);
  color: white;
  flex: 0 1 auto;
  height: 100vh;
  padding: 1rem 3rem;
  font-size: 0.8rem;

  @media only screen and (max-width: 600px) {
    padding: 1rem 1rem;
  }
`

const Container = styled.div`
  display: flex;
  padding: 2rem 0;
  justify-content: space-between;

	@media
	  only screen
    and (max-width: 760px), (min-device-width: 768px)
    and (max-device-width: 1024px)  {
      flex-direction: column;
    }
`

const Link = styled.a`
  color: white;
  margin-right: 1rem;
`

const LinksContainer = styled.div`
  margin-bottom: 5rem;
`
const Logo = styled.img`
  margin-top: 1rem;
  height: 60px;
`
const Text = styled.p`
  margin-top: 1rem;
`
const UpdatedText = styled(Text)``
const Copyright = styled(Text)``

const RightContainer = styled.div`
  flex-basis: 25%;
`
const Disclaimer = styled.div`
  margin: 0 2rem 1em 0;
  white-space: pre-line;
`

const Footer = () => {
  return (
    <Styles>
      <Container>
        <div>
          <Disclaimer>{(CustomerData.disclaimer)}</Disclaimer>
          <LinksContainer>
            <Link key={0} href="https://www.cms.gov/healthplan-price-transparency">About the Transparency in Coverage Act</Link>
            <Link key={1} href="https://github.com/CMSgov/price-transparency-guide">CMSGov Technical Implementation Guide</Link>
            {CustomerData.custom_links.map((link, idx) => <Link key={idx+2} href={link.url}>{link.text}</Link>)}
          </LinksContainer>
          <UpdatedText>Website published on: {String(new Date(Date.parse(CustomerData.updatedAt)))}</UpdatedText>
          <Copyright>©{new Date().getFullYear()} Sapphire365. All Rights Reserved.</Copyright>
        </div>
        <RightContainer>
          <div>Powered by</div>
          <a href="https://getsapphire.com">
            <Logo src="sapphire-with-tag-logo.svg" alt="Sapphire-Digital logo" />
          </a>
        </RightContainer>
      </Container>
    </Styles>
  )
}

export default Footer;
