import React from "react";
import { useState } from "react";
import Fuse from 'fuse.js'
import Input from './Input';
import styled from "styled-components";
import SearchCriteria from '../components/utils/SearchCriteria';
import CustomerData from "../content/customer.json"
import { useASOTocs } from "../hooks/useASOTocs.js"

const Container = styled.div`
  margin: 2rem 0;
  display: flex;
`
const SearchContainer = styled.div`
  margin-left: 3rem;
  &:first-child {
    margin-left: 0;
  }
`
const Label = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  font-weight: bold;
  margin-bottom: 0.25rem;
`

const Search = ({onChange}) => {
  const [identifierQuery, setIdentifierQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const criteria = new SearchCriteria();
  const handleIdentifierChange = (e) => {
    const query = e.currentTarget.value
    query ? criteria.add('toc.reporting_structure.reporting_plans.plan_id', query) : criteria.remove('toc.reporting_structure.reporting_plans.plan_id')
    setIdentifierQuery(query);
    const results = fuse.search(criteria.toFuseQuery())
    onChange(filteredResults(results))
  }
  const handleNameChange = (e) => {
    const query = e.currentTarget.value
    query ? criteria.add('payer_name', query) : criteria.remove('payer_name')
    setNameQuery(query);
    const results = fuse.search(criteria.toFuseQuery())
    onChange(filteredResults(results))
  }
  const filteredResults = (results) => (
    results.map((result) => result.item)
  )
  const fuse = new Fuse(useASOTocs(), {
    threshold: 0.3,
    minMatchCharLength: 3,
    useExtendedSearch: true,
    keys: criteria.searchableFields,
  });

  return (
    <Container>
      {CustomerData.enableNameSearch &&
        <SearchContainer>
          <Label htmlFor="name">Search by Company Name</Label>
          <Input
            id="name"
            onChange={handleNameChange}
            value={nameQuery}
          />
          <small>Example: Sapphire-Digital</small>
        </SearchContainer>
      }
      {CustomerData.enableIdentifierSearch &&
        <SearchContainer>
          <Label htmlFor="identifier">Search by HIOS/EIN Identifier</Label>
          <Input
            id="identifier"
            onChange={handleIdentifierChange}
            value={identifierQuery}
          />
          <small>Example: 12345678</small>
        </SearchContainer>
      }
    </Container>
  )
}

export default Search;
