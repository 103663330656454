import React from "react";
import { useTable, useExpanded } from "react-table"
import styled from "styled-components";

const PlanIdType = styled.span`
  text-transform: uppercase;
`

const CompanyName = styled.span`
  font-weight: bold;
`

const Container = styled.div`
  table {
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    thead {
      background-color: var(--light-gray);
      text-transform: uppercase;
      font-size: 0.8rem;
    }

    tr {
      border-bottom: 1px solid var(--gray);
    }

    th,
    td {
      text-align: left;
    }

    td {
      padding: 1.25rem 1rem 1rem;;
    }

    th {
      padding: 1.5rem 1rem;
      border-bottom: 1px solid #eeeeee;
    }
  }

	@media
	  only screen
    and (max-width: 760px), (min-device-width: 768px)
    and (max-device-width: 1024px)  {

    /* Disable expansion when small resolution */
    #expander {
      display: none !important;
    }

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }

    tr:nth-child(odd) {
      background: #ccc;
    }

		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}

		/*
		Label the data
    You could also use a data-* attribute and content for this. That way "bloats" the HTML, this way means you need to keep HTML and CSS in sync. Lea Verou has a clever way to handle with text-shadow.
		*/
		td:nth-of-type(1):before { content: "Files"; }
		td:nth-of-type(2):before { content: "Company Name"; }
		td:nth-of-type(3):before { content: "File Name"; }
		td:nth-of-type(4):before { content: "Plan Name"; }
		td:nth-of-type(5):before { content: "Plan ID Type"; }
		td:nth-of-type(6):before { content: "Plan ID"; }
		td:nth-of-type(7):before { content: "Actions"; }
	}
`

const TableOfContentsTable = ({ columns, data, children, initialState }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* eslint-disable no-unused-vars */
    state: { expanded }
  } = useTable(
    {
      columns,
      data,
      initialState,
    },
    useExpanded,
  )

  return (
    <Container>
      <table {...getTableProps()}>
        <thead>
          {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
              headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {
          rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {
                row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </Container>
  )
}

export {
  PlanIdType,
  TableOfContentsTable,
  CompanyName
};
