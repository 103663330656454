import React from 'react';
import '../styles/global.css';
import styled from 'styled-components';

const Styles = styled.div`
  flex: 0 1 auto;
`;

export default function Layout({ children }) {
  return <Styles>{children}</Styles>;
}
