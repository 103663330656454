import React from "react";
import styled from "styled-components";
import CustomerData from "../content/customer.json"

const Container = styled.div`
  background-color: var(--cobalt);
  padding: 3.5rem 3rem;
  margin: 0;
  @media only screen and (max-width: 600px) {
    padding: 1.5rem 1rem;
  }
`
const Title = styled.h1`
  color: white;
  line-height: 150%;
  font-size: 42px;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
`
const Message = styled.p`
  white-space: pre-wrap;
  color: white;
  line-height: 150%;
  font-size: 24px;
  font-weight: 300;
  margin: 0;
`

const CustomMessage = styled(Message)`
  margin-top: 1em;
  margin-bottom: 0;
`

const Hero = () => {
  const heroMessage = "Providing in-network provider negotiated rates and historical out-of-network allowed amounts through machine-readable files."
  return (
    <Container>
      <Title>MRF Hub</Title>
      <Message>{heroMessage}</Message>
      {CustomerData.customHeroMessage ? <CustomMessage>{CustomerData.customHeroMessage}</CustomMessage> : null }
    </Container>
  )
}
export default Hero;
