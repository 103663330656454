import React from "react";
import Toggle from '../components/Toggle';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  margin: 2.5rem 0;
`

const PayerTypeToggles = ({
  selectedPayerType = "fullyInsured",
  displayAccountSpecific,
  displayFullyInsured,
  handleClick,
}) => {
  return (
    <Container role="tablist">
      {displayFullyInsured && <Toggle
        label="Fully Insured"
        ariaControls="tab-fully-insured"
        active={selectedPayerType === "fullyInsured"}
        onClick={(e) => handleClick(e, "fullyInsured")}
      />}
     {displayAccountSpecific && <Toggle
        label="Self Insured"
        ariaControls="tab-self-insured"
        active={selectedPayerType === "selfInsured"}
        onClick={(e) => handleClick(e, "selfInsured")}
      />}
    </Container>
  )
}

export default PayerTypeToggles
