class SearchCriteria {
  constructor() {
    this.currentFilters = {};
    this.searchableFields = [
      'payer_name',
      'toc.reporting_structure.reporting_plans.plan_id',
    ];
  }

  add(key, values) {
    this.currentFilters = {
      ...this.currentFilters,
      [key]: values,
    };

    return this.currentFilters;
  }

  remove(key) {
    const filterCopy = this.currentFilters;
    delete filterCopy[key];
    this.currentFilters = filterCopy;

    return this.currentFilters;
  }

  queryFilterToFuseQuery() {
    if (!this.currentFilters.query) return [];
    const expressions = this.searchableFields.map((field) => ({ [field]: this.currentFilters.query }));
    return [{ $or: expressions }];
  }

  toFuseQuery(exact = false) {
    const filterCopy = { ...this.currentFilters };
    delete filterCopy.query;
    const expressions = Object.entries(filterCopy).map(([k, v]) => {
      const queryWithoutWhitespace = v.replace(/\s/g, "");
      return { [k]: exact ? `=${queryWithoutWhitespace}` : queryWithoutWhitespace }
    });
    return {
      $and: expressions.concat(this.queryFilterToFuseQuery()),
    };
  }
}

export default SearchCriteria;
