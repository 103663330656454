import styled from "styled-components";

const Button = styled.a`
  padding: 0.5rem 1rem;
  border: 1px solid var(--aegean-color);
  margin-left: 0.5rem;
  color: var(--cobalt);
  text-decoration: none;
  white-space: nowrap;
  border-radius: 5px;
  font-size: 0.8rem;
  font-weight: 600;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: var(--cobalt);
    opacity: 0.8;
    color: white;
  }
`;

const ActiveButton = styled(Button)`
  background-color: var(--cobalt);
  color: white;
  &:hover {
    opacity: 0.8;
  }

  &:focus {
    outline-color: var(--light-blue);
  }
`

export {
  Button,
  ActiveButton,
};
