import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import styled from 'styled-components';

const Container = styled.div`
  display: block;
  width: 50%;
  padding: 1rem;
  position: absolute;
  background-color: white;
  top: ${(props) => props.yCoordinate - 24}px;
  right: 5%;
  box-shadow: var(--box-shadow);
`;
const Header = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 0 0.5rem 0.75rem 0;
  height: 24px;
`;
const Message = styled.span`
  display: block;
  font-weight: bold;
  position: absolute;
  bottom: 0;
`;

const CloseIcon = styled.a`
  text-align: center;
  line-height: 20px;
  position: absolute;
  color: black;
  bottom: -8px;
  right: 0;
`;

const CopyLink = ({
  children, show, handleClose, xCoordinate, yCoordinate, copyLinkInputEl,
}) => {
  return (
    show
      && (
      <Container xCoordinate={xCoordinate} yCoordinate={yCoordinate}>
        <Header>
          <Message>Link copied!</Message>
          <CloseIcon onClick={(e) => handleClose(e)} href=""><IoCloseCircleOutline size="24px" /></CloseIcon>
        </Header>
        {children}
      </Container>
      )
  );
}

export default CopyLink;
