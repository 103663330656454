import React, { useState, useMemo } from 'react';
import { PlanIdType, TableOfContentsTable, CompanyName } from './TableOfContentsTable';
import { IoChevronDownOutline, IoChevronForwardOutline } from 'react-icons/io5';
import { Button, ActiveButton } from '../components/Button';
import CustomerData from "../content/customer.json"
import CopyLink from './CopyLink';
import Input from './Input';

const TableOfContentsList = ({tocs, initialState, ariaLabelledby}) => {
  const [showCopyLink, setShowCopyLink] = useState(false)
  const [copyLinkUrl, setCopyLinkUrl] = useState(false)
  const [copyLinkXCoordinate, setCopyLinkXCoordinate] = useState("")
  const [copyLinkYCoordinate, setCopyLinkYCoordinate] = useState("")
  const handleCopyLink = (e) => {
    e.preventDefault();
    setCopyLinkXCoordinate(e.clientX);
    setCopyLinkYCoordinate(e.clientY + e.view.scrollY);
    setCopyLinkUrl(e.target.href);
    setShowCopyLink(true);
    navigator.clipboard.writeText(e.target.href)
  };
  const handleCopyLinkClose = (e) => {
    e.preventDefault();
    setShowCopyLink(false);
  };
  const subRows = (reportingStructure) => (
    reportingStructure.flatMap((rs) => (
      rs.reporting_plans.map((rp) => ({
        payer_name: '',
        filename: '',
        plan_name: rp.plan_name,
        plan_id_type: rp.plan_id_type,
        plan_id: rp.plan_id,
        action: '',
      }))
    ))
  );
  const columns = useMemo(
    () => [
      CustomerData.collapsibleTocRows && {
        Header: 'Files',
        id: 'expander', // Make sure it has an ID
        Cell: ({ row }) =>
          row.canExpand ? (
            <button
              id="expander"
              aria-label={`Expand row to see plans for payer: ${row.original.payer_name}`}
              aria-expanded={row.isExpanded ? "true" : "false"}
              {...row.getToggleRowExpandedProps({
                style: {
                  display: 'flex',
                  background: 'white',
                  border: 'none'
                },
              })}
            >
              {row.isExpanded ? <IoChevronDownOutline /> : <IoChevronForwardOutline />}
            </button>
          ) : null
      },
      {
        Header: 'Company Name',
        accessor: 'payer_name',
        Cell: cell => <CompanyName>{cell.value}</CompanyName>,
      },
      {
        Header: 'File Name',
        accessor: 'file_name',
      },
      {
        Header: 'Plan Name',
        accessor: 'plan_name',
      },
      {
        Header: 'Plan ID Type',
        accessor: 'plan_id_type',
        Cell: cell => <PlanIdType>{cell.value}</PlanIdType>,
      },
      {
        Header: 'Plan ID',
        accessor: 'plan_id',
      },
      {
        Header: 'Actions',
        accessor: 'action',
        Cell: (props) => {
          return (
            <p>{props.value}</p>
          );
        }
      },
    ].filter(Boolean),
    []
  );
  const data = useMemo(
    () => tocs.map((toc, i) => (
      {
        payer_name: toc.payer_name,
        file_name: toc.file_name,
        action: (
          <>
            <Button
              role="button"
              onClick={(e) => handleCopyLink(e)}
              href={toc.url}
              aria-label={`Copy Link to Table of Contents file: ${toc.file_name}`}
            >
              Copy Link
            </Button>
            <ActiveButton
              role="button"
              href={toc.url}
              aria-label={`Download Table of Contents file: ${toc.file_name}`}
            >
              Download
            </ActiveButton>
          </>
        ),
        subRows: subRows(toc.toc.reporting_structure)
      }
    )),
    [tocs]
  );

  return (
    <div aria-labelledby={ariaLabelledby}>
      <TableOfContentsTable columns={columns} data={data} initialState={initialState} />
      <CopyLink
        xCoordinate={copyLinkXCoordinate}
        yCoordinate={copyLinkYCoordinate}
        handleClose={handleCopyLinkClose}
        show={showCopyLink}
      >
        <Input
          width={"97%"}
          value={copyLinkUrl}
          readOnly
          ref={(node) => { node && node.select();}}
        />
      </CopyLink>
    </div>
  )
};

export default TableOfContentsList;
