import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import TableOfContentsList from '../components/TableOfContentsList';
import Header from '../components/Header';
import Layout from '../components/Layout';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Search from '../components/Search';
import PayerTypeToggles from '../components/PayerTypeToggles';
import Customer from '../content/customer.json';
import { useFullyInsuredTocs } from '../hooks/useFullyInsuredTocs';
import { useFilteredASOTocs } from '../hooks/useFilteredASOTocs';
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100vh;
`

const Main = styled.main`
  margin: 3rem 3rem;
  @media only screen and (max-width: 600px) {
    margin: 1.5rem 1rem;
  }
`

const IndexPage = () => {
  const allFullyInsuredTocs = useFullyInsuredTocs();
  const allASOSpecificTocs = useFilteredASOTocs();
  const [selectedPayerType, setSelectedPayerType] = useState(allFullyInsuredTocs.length > 0 ? 'fullyInsured' : 'selfInsured');
  const [asoSpecificTocs, setASOSpecificTocs] = useState(Customer.displayAllASOs ? allASOSpecificTocs : []);
  const fullyInsuredInitialState = Customer.collapsibleTocRows ? '' : { expanded: allFullyInsuredTocs.map((toc, idx) => ({ [idx]: true })) }
  const [asoSpecificInitialState, setAsoSpecificInitialState] = useState(!Customer.collapsibleTocRows && Customer.displayAllASOs ? { expanded: allASOSpecificTocs.map((toc, idx) => ({ [idx]: true }))} : '');
  const changePayerType = (e, label) => {
    setSelectedPayerType(label);
    e.preventDefault();
  };

  const handleResultsUpdate = (value) => {
    if (value.length === 0 && Customer.displayAllASOs) {
      setASOSpecificTocs(allASOSpecificTocs);
      if (Customer.collapsibleTocRows) {
        setAsoSpecificInitialState('');
      } else {
        // disabled expand/collapse feature -- render all rows expanded
        setAsoSpecificInitialState({ expanded: allASOSpecificTocs.map((toc, idx) => ({ [idx]: true }))})
      }
    } else {
      setASOSpecificTocs(value);
      if (Customer.collapsibleTocRows) {
        // only expands first 2 results
        setAsoSpecificInitialState({ expanded: { 0: true, 1: true } });
      } else {
        // disabled expand/collapse feature -- render all rows expanded
        setAsoSpecificInitialState({ expanded: value.map((toc, idx) => ({ [idx]: true }))})
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>S365 MRF Hub</title>
        <html lang="en" />
      </Helmet>
      <Container>
        <Layout>
          <Header />
          <Hero />
          <Main>
            <PayerTypeToggles
              selectedPayerType={selectedPayerType}
              handleClick={changePayerType}
              displayAccountSpecific={allASOSpecificTocs.length > 0}
              displayFullyInsured={allFullyInsuredTocs.length > 0}
            />
            { selectedPayerType !== 'fullyInsured' && <Search onChange={handleResultsUpdate} /> }
            {
              selectedPayerType === 'fullyInsured'
                ? <TableOfContentsList role="tabpanel" ariaLabelledby="tab-fully-insured" tocs={allFullyInsuredTocs} initialState={fullyInsuredInitialState} />
                : <TableOfContentsList role="tabpanel" ariaLabelledby="tab-self-insured" tocs={asoSpecificTocs} initialState={asoSpecificInitialState} />
            }
          </Main>
        </Layout>
        <Footer />
      </Container>
    </>
  );
};
export default IndexPage;
