import React from "react";
import styled from "styled-components";
import CustomerData from "../content/customer.json"

const Container = styled.div`
  display: flex;
  margin: 0 3rem;
  min-height: 72px;

  @media only screen and (max-width: 600px) {
    align-items: center;
    margin: 1rem 1rem;
    min-height: 42px;
  }
`
const CustomerContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex: 50%;
  }
`
const SapphireTitleContainer = styled.div`
  display: flex;
  align-items: center;
  @media only screen and (max-width: 600px) {
    flex: 50%;
  }
`
const VerticalLine = styled.hr`
  min-height: 100%;
  max-height: 100vh;
  width: 2px;
  background: var(--gray);
  border: none;
  margin: 0.75rem 1rem;
`
const Logo = styled.img`
  max-width: 100%;
  height: 42px;

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    height: 48px;
  }
`
const SapphireLogo = styled.img`
  max-width: 265px;
  height: 28px;
  @media only screen and (max-width: 600px) {
    max-width: 150px;
    height: 32px;
  }
`

const Header = () => {
  return (
    <Container>
      {
        CustomerData.logo === "" ? null : (
          <>
            <CustomerContainer>
              <Logo src={CustomerData.logo} alt={`${CustomerData.name} logo`}/>
            </CustomerContainer>
          </>
        )
      }
      {
        CustomerData.suppressSapphireLogo ? null : (
          <>
            <VerticalLine />
            <SapphireTitleContainer>
              <a style={{display: 'flex', alignItems: 'center'}} href="https://www.sapphiremrfhub.com">
                <SapphireLogo src={'sapphire-header-logo.svg'} alt="Sapphire-Digital logo" />
              </a>
            </SapphireTitleContainer>
          </>
        )
      }
    </Container>
  )
}

export default Header;
