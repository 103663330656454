import styled from 'styled-components';

const Input = styled.input`
  padding: 0.5rem 0.25rem;
  font-size: 16px;
  &:focus {
    outline-color: var(--aegean-color);
  }
  width: 100%;
`;

export default Input;
