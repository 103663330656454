import React from 'react';
import styled from "styled-components";

const Button = styled.a`
  padding: 0.5rem 2rem;
  border-bottom: 1px solid var(--gray);
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  color: var(--dark-gray);
  font-size: 0.8rem;
`

const Toggle = ({
  active = false,
  label,
  className,
  ariaLabelledby,
  ariaControls,
  onClick,
}) => {
  const activeStyle = {
    "borderBottom": "2px solid var(--ultramarine)",
    "color": "var(--ultramarine)"
  }

  return (
    <>
      {
        <Button
          href=""
          onClick={onClick}
          role="tab"
          aria-selected={active}
          aria-controls={ariaControls}
          id={ariaControls}
          style={ active ? activeStyle : null }
        >
          {label}
        </Button>}
    </>
  )
}

export default Toggle;
