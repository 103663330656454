import { useStaticQuery, graphql } from 'gatsby';

export const useASOTocs = () => {
  const { allTocsJson } = useStaticQuery(graphql`
    query {
      allTocsJson(filter: {is_fully_insured: {eq:false} }) {
        edges {
          node {
            toc {
              reporting_structure {
                reporting_plans {
                  plan_id
                  plan_id_type
                  plan_name
                }
              }
            }
            payer_name
            file_name
            is_fully_insured
            is_suppressed
            url
          }
        }
      }
    }
  `);

  return allTocsJson.edges.map((edge) => edge.node);
};
